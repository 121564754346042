<template>
  <div>
    <form v-if="form" class="mt-5" @submit.prevent="submit">
      <b-field :label="$tc('accessory_type')" label-for="product" horizontal>
        <b-select id="product" v-model="form.productType" required>
          <option v-for="p in productTypes" :key="p.id" :value="p['@id']">
            {{ p.name }}
          </option>
        </b-select>
      </b-field>

      <CustomInput v-model="form.name" :label="$t('name')"></CustomInput>

      <CustomDatePicker
        v-model="form.manufacturingDate"
        :label="$t('manufacturing_date')"
      ></CustomDatePicker>

      <CustomInput
        v-model="form.manufacturingCosts"
        :label="$t('manufacturing_costs')"
        :type="'number'"
        :icon="'currency-eur'"
      ></CustomInput>

      <CustomDatePicker
        v-model="form.deliveryDate"
        :label="$t('delivery_date')"
      ></CustomDatePicker>

      <CustomInput
        v-model="form.trackAndTraceNumber"
        :label="$t('track_and_trace_number')"
      ></CustomInput>

      <CustomInput
        v-model="form.trackAndTraceLastKnownLocation"
        :label="$t('track_and_trace_last_known_location')"
      ></CustomInput>

      <CustomInput
        v-model="form.totalLifeTimeUsage"
        :label="$t('total_life_time_usage')"
        :type="'number'"
      ></CustomInput>

      <CustomInput
        v-model="form.number"
        :label="$t('number')"
        :type="'number'"
      ></CustomInput>

      <CompanySelect
        v-if="mode === 'create'"
        v-model="form.assignedTo"
        :label="$t('assigned_to')"
        :companies="companies"
        :horizontal="true"
      ></CompanySelect>

      <CompanySelect
        v-if="mode === 'create'"
        v-model="form.systemOwner"
        :label="$t('system_owner')"
        :companies="companies"
        :horizontal="true"
      ></CompanySelect>

      <b-button
        type="is-primary"
        native-type="submit"
        class="mt-5"
        icon-left="content-save"
        :loading="loading"
      >
        {{ $t("save") }}
      </b-button>
    </form>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions } from "vuex";
import CompanySelect from "../../../components/forms/CompanySelect";
import CustomDatePicker from "../../../components/forms/CustomDatePicker";
import CustomInput from "../../../components/forms/CustomInput";

export default {
  components: { CompanySelect, CustomInput, CustomDatePicker },
  props: {
    mode: {
      type: String,
      required: true,
    },
    accessory: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        productType: this.accessory?.productType["@id"],
        name: this.accessory?.name,
        manufacturingDate: this.accessory?.manufacturingDate,
        manufacturingCosts: this.accessory?.manufacturingCosts,
        deliveryDate: this.accessory?.deliveryDate,
        trackAndTraceNumber: this.accessory?.trackAndTraceNumber,
        trackAndTraceLastKnownLocation:
          this.accessory?.trackAndTraceLastKnownLocation,
        totalLifeTimeUsage: this.accessory?.totalLifeTimeUsage,
        sumOfLifetimeRevenue: this.accessory?.sumOfLifetimeRevenue,
        number: this.accessory?.number,
      },
      productTypes: [],
      companies: [],
    };
  },
  async mounted() {
    this.productTypes = await this.getAllProductTypes();

    if (this.mode === "create") {
      this.form = {
        ...this.form,
        productType: this.productTypes[0]["@id"],
        assignedTo: null,
        systemOwner: null,
      };

      this.companies = await this.getAllCompanies({ blocked: false });
    }
  },
  methods: {
    ...mapActions(["getAllCompanies", "getAllProductTypes"]),
    submit() {
      this.$emit("submit", {
        ...this.form,
        manufacturingDate: dayjs(this.form.manufacturingDate).format(
          "YYYY-MM-DD"
        ),
        manufacturingCosts: this.form.manufacturingCosts.toString(),
        deliveryDate: dayjs(this.form.deliveryDate).format("YYYY-MM-DD"),
        totalLifeTimeUsage: this.form.totalLifeTimeUsage.toString(),
      });
    },
  },
};
</script>
