<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div class="page-header">
      <h1>
        <span>{{ $tc("accessory", 2) }} &ndash;</span>
        {{ $t("create") }}
      </h1>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <BackButton :parent-route="{ name: 'DeviceIndex' }"></BackButton>

        <AccessoryForm :mode="'create'" @submit="submit"></AccessoryForm>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BackButton from "../../components/BackButton";
import AccessoryForm from "./partials/AccessoryForm";

export default {
  name: "AccessoryCreate",
  components: { BackButton, AccessoryForm },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["createAccessory"]),
    submit(payload) {
      this.loading = true;

      this.createAccessory(payload)
        .then((accessory) => {
          this.$buefy.toast.open({
            message: this.$t("accessory_created"),
            type: "is-primary",
          });
          this.$router.push({
            name: "AccessoryView",
            params: { id: accessory.id },
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
